<template>
  <div class="all-box">
    <img class="bg-top" src="@/assets/img/performance/bg-4.png" />
    <img class="bg-bottom" src="@/assets/img/performance/bg-5.png" />
    <div class="foun-top">
      <Breadcrumb class="bread"></Breadcrumb>
    </div>
    <div class="foun-box">
      <div class="search">
        <el-input
          v-model="input1"
          @clear="handleClear"
          placeholder="请输入搜索内容"
          clearable
          class="input"
        >
          <el-button
            slot="append"
            @click="search"
            style="background: #f9680d; color: #ffffff"
            >搜索</el-button
          >
        </el-input>
      </div>
      <div class="check-box">
        <div
          :class="selectedLetter == item ? 'check-active' : 'check'"
          v-for="item in searchList"
          :key="item"
          @click="selectLetter(item)"
        >
          {{ item }}
        </div>
      </div>
      <span class="overview"
        >本次共搜索到{{ this.pager.totalCount }}条相关内容</span
      >

      <div class="spContent-box">
        <div
          class="content"
          v-for="item in highlightedDataList"
          v-if="item.item == 'repertoire' || item.item == 'repertoireActor'"
          @click="toDetail(item)"
          :key="item.id"
        >
          <div class="left">
            <img :src="item.image" :onerror="defaultImg" />
          </div>
          <div class="right">
            <div class="buy" v-if="item.onShowFlag == 'ticketing'">
              <button class="btn-buy" @click="handleBuy">购票</button>
            </div>
            <div class="title" v-html="item.repertoireName"></div>
            <div class="tag">{{ item.playTypeName }}</div>
            <div
              style="display: flex"
              class="line-info"
              v-if="item.actorName && item.role"
            >
              <span> {{ `演员：${item.actorName}` }}</span>
              <div style="margin-left: 0.5208vw">
                饰：<span style="color: red">{{ item.role }}</span>
              </div>
            </div>
            <p class="line-info">
              <c-image
                :src="require('@/assets/img/performance/reloadtime@2x.png')"
              />
              <span v-if="item.showTime">上演日期：{{ item.showTime }}</span>
              <!-- <span v-if="item.sell2">上演日期：{{ item.sell2 }}</span> -->
            </p>
            <p class="line-info">
              <c-image :src="require('@/assets/img/performance/bank@2x.png')" />
              <span>演出剧场：{{ item.theatresName }}</span>
            </p>
            <div class="line-info price-info">
              <c-image
                style="margin-top: -48px"
                :src="require('@/assets/img/performance/creditcard@2x.png')"
              />
              <div class="line-price">
                <span>演出票价：</span>
                <p class="price">{{ item.price || "-" }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <div
          class="content"
          v-for="item in highlightedDataList"
          v-if="item.item != 'repertoire' && item.item != 'repertoireActor'"
          @click="toDetail(item)"
          :key="item.id"
        >
          <div class="left">
            <img :src="item.image" :onerror="defaultImg" />
          </div>
          <div class="right">
            <div v-if="item.title" class="title" v-html="item.title"></div>
            <div v-if="item.name" class="title" v-html="item.name"></div>
            <div
              v-if="item.repertoireName"
              class="title"
              v-html="item.repertoireName"
            ></div>
            <div v-if="item.descript" class="body" v-html="item.descript"></div>
            <div v-if="item.body" v-html="item.body" class="body"></div>
            <div v-if="item.price" v-html="item.price" class="body"></div>
            <div
              v-if="item.publishPlace"
              class="body"
              v-html="item.publishPlace"
            ></div>
            <div v-if="item.time" class="time">{{ item.time }}</div>
            <div v-if="item.publishDate" class="time">
              {{ item.publishDate }}
            </div>
            <div v-if="item.entertainerType" class="type">
              {{ item.entertainerType }}
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-pagination
          v-if="dataList.length > 0"
          background
          layout="prev, pager, next"
          @current-change="handlePageChange"
          :page-sizes="pager.pageSizes"
          :current-page="pager.pageNum"
          :total="pager.totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/pc/Breadcrumb.vue";
import { getAllList } from "@/service/search";
import { getArticleDetail, articleHit } from "@/service/culture";
export default {
  components: {
    Breadcrumb,
  },
  computed: {
    highlightedDataList() {
      const searchString = this.input1; // 替换为您要搜索的字符串
      if (!searchString) return this.dataList;
      if (searchString !== "" && this.dataList.length !== 0) {
        return this.dataList.map((item) => {
          const title = item.title
            ? item.title.replace(
                new RegExp(searchString, "g"),
                `<span style='color:red;'>${searchString}</span>`
              )
            : "";
          const descript = item.descript
            ? item.descript.replace(
                new RegExp(searchString, "g"),
                `<span style='color:red;'>${searchString}</span>`
              )
            : "";
          const name = item.name
            ? item.name.replace(
                new RegExp(searchString, "g"),
                `<span style='color:red;'>${searchString}</span>`
              )
            : "";
          const publishPlace = item.publishPlace
            ? item.publishPlace.replace(
                new RegExp(searchString, "g"),
                `<span style='color:red;'>${searchString}</span>`
              )
            : "";
          const repertoireName = item.repertoireName
            ? item.repertoireName.replace(
                new RegExp(searchString, "g"),
                `<span style='color:red;'>${searchString}</span>`
              )
            : "";
          const body = item.body
            ? item.body.replace(
                new RegExp(searchString, "g"),
                `<span style='color:red;'>${searchString}</span>`
              )
            : "";
          const price = item.price
            ? item.price.replace(
                new RegExp(searchString, "g"),
                `<span style='color:red;'>${searchString}</span>`
              )
            : "";
          return {
            ...item,
            title,
            descript,
            name,
            publishPlace,
            repertoireName,
            body,
            price,
          };
        });
      }
    },
  },
  data() {
    return {
      defaultImg:
        'this.src="' + require("../../../assets/imgs/noData.jpg") + '"',
      pager: {
        pageNum: 1,
        pageSize: 10,
        totalPage: 0,
        totalCount: 0,
      },
      form: {
        searchType: "all",
        keywords: "",
        status: 1,
      },
      input1: "",
      searchList: ["综合", "购票", "剧目", "资讯", "人艺人", "视频"],
      selectedLetter: "综合",
      number: 24,
      dataList: [],
    };
  },
  created() {
    this.input1 = this.$route.query.search;
    this.form.keywords = this.$route.query.search;
    this.getDate();
  },
  methods: {
    toDetail(item) {
      if (item.item == "repertoire") {
        //剧目

        window.open(`#/performance/detail/${item.id}`, "_blank");
      } else if (item.item == "ticketing") {
        //购票
        const href = "http://piao.bjry.com";
        window.open(href, "_blank");
      } else if (item.item == "article") {
        //资讯
        console.log(item.id, item.source);
        const { href } = this.$router.resolve({
          path: "/ArtsInformation/detail",
          query: {
            id: item.id,
            page: "1",
          },
        });
        window.open(href, "_blank");
      } else if (item.item == "entertainer") {
        //人艺人
        const { href } = this.$router.resolve({
          path: "/PeopleEntertainers/detail",
          query: {
            id: item.id,
            page: "7",
          },
        });
        window.open(href, "_blank");
      } else if (item.item == "videos") {
        //视频
        window.open(`#/culture/video/detail/${item.id}`);
      } else if (item.item == "exhibitionDisplay") {
        //陈列展品
        const { href } = this.$router.resolve({
          path: "/theatreMuseum/exhibitDetail",
          query: {
            id: item.id,
          },
        });
        window.open(href, "_blank");
      } else if (item.item == "exhibition") {
        //数字展览
        const { href } = this.$router.resolve({
          path: "/theatreMuseum/digitalDetail",
          query: {
            id: item.id,
          },
        });
        window.open(href, "_blank");
      } else if (item.item == "pdf") {
        articleHit(item.id);
        getArticleDetail(item.id).then((res) => {
          window.open(
            `${location.origin}${
              location.pathname || "/"
            }static/read.html?url=${encodeURIComponent(item.source)}&pageNum=${
              res.data.attatchmentUrl
            }`
          );
        });
      } else if (item.item == "theatre" && item.level2 == "shoudu1") {
        const { href } = this.$router.resolve({
          path: "/renyiTheatre/capitalTheatre",
        });
        window.open(href, "_blank");
      } else if (item.item == "theatre" && item.level2 == "renyishiyan") {
        const { href } = this.$router.resolve({
          path: "/renyiTheatre/capitalTheatre",
          query: {
            id: 5,
          },
        });
        window.open(href, "_blank");
      } else if (item.item == "theatre" && item.level2 == "beijin") {
        const { href } = this.$router.resolve({
          path: "/renyiTheatre/theatreCenter",
        });
        window.open(href, "_blank");
      } else if (item.item == "theatre" && item.level2 == "caoyu") {
        const { href } = this.$router.resolve({
          path: "/renyiTheatre/theatreCenter",
          query: {
            id: 7,
          },
        });
        window.open(href, "_blank");
      } else if (item.item == "theatre" && item.level2 == "renyi") {
        const { href } = this.$router.resolve({
          path: "/renyiTheatre/theatreCenter",
          query: {
            id: 8,
          },
        });
        window.open(href, "_blank");
      } else if (item.item == "theatre" && item.level2 == "JYJC") {
        const { href } = this.$router.resolve({
          path: "/renyiTheatre/juYinTheatre",
        });
        window.open(href, "_blank");
      } else if (item.item == "repertoireActor") {
        window.open(`#/performance/detail/${item.id}`, "_blank");
      }
    },
    handleClear() {
      this.form.keywords = this.input1;
      this.getDate();
    },
    search() {
      this.form.keywords = this.input1;
      this.getDate();
    },
    async getDate() {
      const params = { ...this.form, ...this.pager };
      const res = await getAllList(params);
      this.dataList = res.data.data;
      console.log(res);
      this.pager = {
        // pageNum: res.data.pageNum,
        totalPage: res.data.totalPage,
        // pageSize: 10,
        totalCount: res.data.totalCount,
      };
    },
    selectLetter(item) {
      this.selectedLetter = item;
      if (item == "综合") {
        this.form.searchType = "all";
      } else if (item == "购票") {
        this.form.searchType = "ticketing";
      } else if (item == "资讯") {
        this.form.searchType = "article";
      } else if (item == "人艺人") {
        this.form.searchType = "entertainer";
      } else if (item == "视频") {
        this.form.searchType = "videos";
      } else if (item == "剧目") {
        this.form.searchType = "repertoire";
      }
      this.getDate();
    },
    handleBuy() {
      const href = "http://piao.bjry.com";
      window.open(href, "_blank");
    },
    handlePageChange(val) {
      this.pager.pageNum = val;
      this.getDate();
    },
  },
};
</script>
<style scoped lang="less">
.btn-buy {
  background-color: var(--primary-color);
  color: #ffffff;
  width: 4.375vw;
  height: 1.7708vw;
  font-size: 0.7292vw;
  border-radius: 0.2083vw;
  outline: none;
  border: none;
  cursor: pointer;
}
.all-box {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8fa;
  margin-top: -1.0417vw;
  z-index: 2;

  .bg-top {
    position: absolute;
    z-index: -1;
  }

  .bg-bottom {
    position: absolute;
    right: 0;
    bottom: 31.25vw;
    z-index: -1;
  }

  .foun-top {
    width: 15.625vw;
    height: 0.7292vw;
    margin-top: 1.0417vw;
    margin-left: 13.5417vw;
    z-index: 5;

    .bread {
      padding-top: 1.0417vw;
    }
  }

  .foun-box {
    height: auto;
    min-height: 53.75vw;
    /* 设置最小高度为 1032px */
    width: 71.875vw;
    margin: 2.6042vw 13.5417vw 2.6042vw 13.5417vw;
    background: #ffffff;
    position: relative;
    z-index: 5;

    .search {
      padding-top: 2.0833vw;
      width: calc(100% - 20.8333vw);
      height: 3.3333vw;
      padding-left: 10.4167vw;
      padding-right: 10.4167vw;
    }

    .check-box {
      margin: 1.0417vw 10.4167vw;
      display: flex;
      height: 1.875vw;
      width: 20.3125vw;
      font-size: 0.8333vw;

      .check:first-of-type {
        margin: 0;
      }

      .check {
        // width: 68px;
        min-width: 3.5417vw;
        height: 1.875vw;
        line-height: 1.875vw;
        border-radius: 0.2083vw 0.2083vw 0.2083vw 0.2083vw;
        border: 0.0521vw solid;
        opacity: 1;
        margin-left: 0.625vw;
        text-align: center;
        cursor: pointer;
      }

      .check-active:first-of-type {
        margin-left: 0;
      }

      .check-active {
        // width: 68px;
        min-width: 3.5417vw;
        height: 1.875vw;
        line-height: 1.875vw;
        border-radius: 0.2083vw 0.2083vw 0.2083vw 0.2083vw;
        border: 0.0521vw solid;
        opacity: 1;
        margin-left: 0.625vw;
        color: #ffffff;
        background: #f9680d;
        text-align: center;
        cursor: pointer;
      }
    }

    .overview {
      margin: 1.5625vw 10.4167vw 0 10.4167vw;
      font-weight: 700;
      font-size: 0.9375vw;
    }

    .content-box {
      padding-left: 10.4167vw;
      padding-right: 10.4167vw;
      margin-top: 1.0417vw;

      .content {
        width: 100%;
        // height: 255px;
        min-height: 13.2813vw;
        background: #f8f8fa;
        display: flex;
        margin-bottom: 1.5625vw;
        cursor: pointer;

        .left {
          width: 15vw;
          height: 11.25vw;
          margin: 1.0417vw 1.5625vw;

          img {
            width: 15vw;
            height: 11.25vw;
            object-fit: contain;
            overflow: hidden;
          }
        }

        .right {
          margin-top: 1.5625vw;
          margin-bottom: 1.0417vw;

          .title {
            font-weight: 700;
            font-size: 0.8333vw;
          }

          .body {
            line-height: 1.1979vw;
            margin-right: 1.0417vw;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* 显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            margin-top: 1.0417vw;
            color: #707070;
            font-size: 0.833vw;
          }

          .time {
            margin-top: 0.7813vw;
            color: #707070;
          }

          .type {
            width: 3.5417vw;
            height: 1.3542vw;
            border-radius: 0.2083vw 0.2083vw 0.2083vw 0.2083vw;
            opacity: 1;
            border: 0.0521vw solid #f9680d;
            color: #f9680d;
            margin-top: 0.7813vw;
            text-align: center;
            line-height: 1.3542vw;
          }
        }
      }
    }

    .spContent-box {
      padding-left: 10.4167vw;
      padding-right: 10.4167vw;
      margin-top: 1.0417vw;

      .content {
        position: relative;
        width: 100%;
        // height: 255px;
        min-height: 13.2813vw;
        background: #f8f8fa;
        display: flex;
        margin-bottom: 1.5625vw;
        cursor: pointer;

        .left {
          width: 15vw;
          height: 11.25vw;
          margin: 1.0417vw 1.5625vw;

          img {
            width: 15vw;
            height: 11.25vw;
            object-fit: contain;
            overflow: hidden;
          }
        }

        .right {
          margin-top: 1.5625vw;
          margin-bottom: 1.0417vw;
          .buy {
            position: absolute;
            top: .5625vw;
            right: 1.5625vw;
          }

          .line-info {
            margin-top: 0.7292vw;
            display: flex;
            align-items: center;
            font-size: 0.7292vw;
            font-weight: 400;
            color: #909399;

            .img {
              width: 0.8333vw;
              height: 0.8333vw;
              margin-right: 0.3125vw;
              flex-shrink: 0;
            }

            .price-info {
              align-items: flex-start;
              line-height: 1.1458vw;

              .img {
                margin-top: -0.625vw;
              }

              span {
                flex-shrink: 0;
              }

              .line-price {
                display: flex;
                font-size: 0.7292vw;

                span {
                  flex-shrink: 0;
                }

                .price {
                  color: var(--primary-color);
                  font-weight: bold;
                  max-width: 23.9583vw;
                }
              }
            }
          }

          .title {
            font-weight: 700;
          }

          .tag {
            display: inline-block;
            margin-top: 1.0417vw;
            height: 1.3021vw;
            // width: 50px;
            min-width: 2.6042vw;
            background: #f8d39f;
            border-radius: 0.1042vw;
            line-height: 1.3021vw;
            font-size: 0.625vw;
            color: #333;
            padding: 0 0.4167vw;
          }

          .body {
            line-height: 1.1979vw;
            margin-right: 1.0417vw;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* 显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            margin-top: 1.0417vw;
            color: #707070;
          }

          .time {
            margin-top: 0.7813vw;
            color: #707070;
          }

          .type {
            width: 3.5417vw;
            height: 1.3542vw;
            border-radius: 0.2083vw 0.2083vw 0.2083vw 0.2083vw;
            opacity: 1;
            border: 0.0521vw solid #f9680d;
            color: #f9680d;
            margin-top: 0.7813vw;
            text-align: center;
            line-height: 1.3542vw;
          }
        }
      }
    }

    .bottom {
      margin-top: 2.8646vw;
      width: 100%;
      height: 1.6667vw;
      margin-bottom: 2.4479vw;
      text-align: center;
    }
  }
}
</style>
<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f9680d;
}
</style>
